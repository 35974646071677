export const HOME_PATH = '/'
export const NO_ACCESS = '/no-access'
export const TERMINATION_PATH = '/termination'
export const TITLE_JOB_CODE_CHANGE_PATH = '/job-title-function'
export const WAGE_CHANGE_PATH = '/wage-change'
export const EPL_LOAN_PATH = '/epl-loan'
export const TRANSFER_PATH = '/transfer'
export const LEAVE_OF_ABSENCE_PATH = '/leave-of-absence'
export const RETURN_FROM_LEAVE_PATH = '/return-from-leave'
export const REHIRE_MANUAL_PATH = '/rehire-manual'
export const REHIRE_PATH = '/rehire'
export const REHIRE_OLD_PATH = '/rehire-old'
export const HOURLY_SALARY = '/hourly-salary'
export const REQUEST_LIST = '/requests'
export const REQUEST_LIST_PENDING_MY_APPROVAL = `${REQUEST_LIST}?pendingApproval=true`
export const REQUEST_LIST_PENDING_MY_APPROVAL_LEGACY = `${REQUEST_LIST}/pending-my-approval`
export const REQUEST_DETAIL = `${REQUEST_LIST}/:id`
export const HELP_PATH = '/help'
export const FEEDBACK_PATH = '/feedback'
export const IMPERSONATE_PATH = '/admin/impersonate'
export const STOREALIGNMENT_PATH = '/store-alignment'
export const FAQ_PATH = '/FAQ'
export const PRACTIONER_ACTION_BOARD = '/practitioner-action-board'
export const HR_ACTION_BOARD = '/hr/action-board'
export const TRAINING_ACTION_BOARD = '/training/action-board'
export const ESIGN = '/esign'
export const ESIGN_LIST = '/hr/esign-list'
export const UPLOAD_FILE_DISPLAY = '/uploads/:requestId/:fileId'
export const UPLOAD_ADP_ROSTER_CSV = '/upload-adp-roster-csv'
export const ACCOUNTING_DENNYSSALES_PATH = '/accounting/dennys-sales'
export const ACCOUNTING_INVOICE_TO_SAGE_CSV_PATH =
  '/accounting/invoice-to-sage-csv'
export const ACCOUNTING_KKD_KORBER_SAGE_MAPPINGS =
  '/accounting/kkd-korber-sage-map'
export const ACCOUNTING_KKD_KORBER_INVOICES = '/accounting/kkd-korber-invoices'
export const SYNC_EMPLOYEE_FROM_ADP_PATH = '/employee'
export const CHANGE_OF_STATUS_PATH = '/change-of-status'
export const TIMEPUNCH_REPORTS = '/timepunch/reports'
export const TIMEPUNCH_AUDIT = '/timepunch/audit'
export const TIMEPUNCH_EXTERNALIMPORTS = '/timepunch/external-imports'
export const TIMEPUNCH_ADPEXPORT = '/timepunch/adp-export'
export const POS_ADP_LINK_CORRECTION_DEPRECATED_20230707 =
  '/payroll/aloha-adp-link-correction'
export const POS_ADP_LINK_CORRECTION = '/payroll/pos-adp-link-correction'
export const UPLOAD_TALENT_REEF_ROSTER_CSV = '/upload-talent-reef-roster-csv'
export const ZOOM_SYNC_PATH = '/it/zoom/sync'
export const GOOGLE_DIRECTORY_SYNC_PATH = '/it/google/update-directory'
export const ADP_PDF_PROCESSOR_PATH = '/payroll/adp-pdf-processor'
export const BORROW_REQUEST_PATH = '/borrow'
export const POS_JOB_CODE_REQUEST_PATH = '/pos-job-code'
export const RELEASE_NOTES_PATH = '/releasenotes'
export const V3_OPERATIONS_LOCATION_EDIT_PATH = '/Operations/Locations/Edit/'
export const V3_OPERATIONS_EMAIL_GROUPS_PATH = '/Operations/Email-Groups'
export const V3_OPERATIONS_AP_INVOICES_CREATE_PATH = '/ap/invoices/create'
export const V3_OPERATIONS_AP_INVOICES_ACTION_BOARD_PATH =
  '/ap/invoices/action-board'
export const V3_HR_OFFER_LETTERS_PATH = '/OfferLetter/OfferLetterList'
export const V3_RISKMGMT_LINKS_PATH = '/risk-mgmt/links'
export const UNAUTHENTICATED_PATHS = [ESIGN]
