import { Box, Link } from '@mui/material'
import * as Sentry from '@sentry/react'
import { FallbackComponent } from 'components/FallbackComponent'
import { ErrorOccurred } from 'components/FallbackNotes'
import { MenuBar } from 'components/MenuBar'
import { CopyrightLine } from 'components/layout/CopyrightLine'
import { Footer } from 'components/layout/Footer'
import { FullHeightBox } from 'components/layout/FullHeightBox'
import { Main } from 'components/layout/Main'
import { useUser } from 'context/Authenticate'
import { useClasses } from 'hooks/useClasses'
import { useSessionTimeout } from 'hooks/useSessionTimeout'
import { EmploymentChangeHome } from 'pages/EmploymentChangeHome'
import { EplLoan } from 'pages/EplLoan'
import { Home } from 'pages/Home'
import NoEnvironmentAccess from 'pages/NoEnvironmentAccess'
import { RehireReinstatement } from 'pages/RehireReinstatement'
import SyncEmployeeFromADP from 'pages/SyncEmployeeFromADP'
import { Termination } from 'pages/Termination'
import UploadAdpRoster from 'pages/UploadAdpRoster'
import { UploadFileDisplayPage } from 'pages/UploadFileDisplayPage'
import UploadTalentReefCsv from 'pages/UploadTalentReefCsv'
import KKDKorberInvoices from 'pages/accounting/KKDKorberInvoices'
import React, { useCallback, useEffect } from 'react'
import {
  Navigate,
  Route,
  Link as RouterLink,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { PathWithWildCard } from 'utils'
import WKSPlusLogo from './assets/WKSPlusLogo.png'
import {
  ACCOUNTING_DENNYSSALES_PATH,
  ACCOUNTING_INVOICE_TO_SAGE_CSV_PATH,
  ACCOUNTING_KKD_KORBER_INVOICES,
  ACCOUNTING_KKD_KORBER_SAGE_MAPPINGS,
  ADP_PDF_PROCESSOR_PATH,
  BORROW_REQUEST_PATH,
  CHANGE_OF_STATUS_PATH,
  EPL_LOAN_PATH,
  ESIGN,
  ESIGN_LIST,
  FAQ_PATH,
  FEEDBACK_PATH,
  GOOGLE_DIRECTORY_SYNC_PATH,
  HELP_PATH,
  HOME_PATH,
  HOURLY_SALARY,
  HR_ACTION_BOARD,
  IMPERSONATE_PATH,
  LEAVE_OF_ABSENCE_PATH,
  NO_ACCESS,
  POS_ADP_LINK_CORRECTION,
  POS_ADP_LINK_CORRECTION_DEPRECATED_20230707 as POS_ADP_LINK_CORRECTION_DEPRECATED,
  POS_JOB_CODE_REQUEST_PATH,
  PRACTIONER_ACTION_BOARD,
  REHIRE_PATH,
  RELEASE_NOTES_PATH,
  REQUEST_DETAIL,
  REQUEST_LIST,
  REQUEST_LIST_PENDING_MY_APPROVAL,
  REQUEST_LIST_PENDING_MY_APPROVAL_LEGACY,
  RETURN_FROM_LEAVE_PATH,
  STOREALIGNMENT_PATH,
  SYNC_EMPLOYEE_FROM_ADP_PATH,
  TERMINATION_PATH,
  TIMEPUNCH_ADPEXPORT,
  TIMEPUNCH_AUDIT,
  TIMEPUNCH_EXTERNALIMPORTS,
  TIMEPUNCH_REPORTS,
  TITLE_JOB_CODE_CHANGE_PATH,
  TRAINING_ACTION_BOARD,
  TRANSFER_PATH,
  UPLOAD_ADP_ROSTER_CSV,
  UPLOAD_FILE_DISPLAY,
  UPLOAD_TALENT_REEF_ROSTER_CSV,
  WAGE_CHANGE_PATH,
  ZOOM_SYNC_PATH,
} from './link-paths'

const styles = {
  link: {
    margin: 5,
  },
}

/**
 * Async Components
 * TODO: Move the rest of the pages to async import for lazy load
 */
const RateChange = React.lazy(() => import('pages/RateChange'))
const EmploymentChangeList = React.lazy(
  () => import('pages/EmploymentChangeList')
)
const PractitionerActionBoard = React.lazy(
  () => import('pages/action-board/PractitionerActionBoard')
)
const HrActionBoard = React.lazy(
  () => import('pages/action-board/HrActionBoard')
)
const TrainingActionBoard = React.lazy(
  () => import('pages/action-board/TrainingActionBoard')
)
const RequestDetail = React.lazy(() => import('pages/StatusChangeDetail'))
const Transfer = React.lazy(() => import('pages/Transfer'))
const HourlySalary = React.lazy(() => import('pages/HourlySalary'))
const JobFunctionTitle = React.lazy(() => import('pages/JobFunctionTitle'))
const Help = React.lazy(() => import('pages/Help'))
const Feedback = React.lazy(() => import('pages/Feedback'))
const Impersonate = React.lazy(() => import('pages/Impersonate'))
const FAQ = React.lazy(() => import('pages/FAQ'))
const ReleaseNotes = React.lazy(() => import('pages/ReleaseNotes'))
const LeaveOfAbsence = React.lazy(() => import('pages/LeaveOfAbsence'))
const ReturnFromLeave = React.lazy(() => import('pages/ReturnFromLeave'))
const StoreAlignment = React.lazy(() => import('pages/StoreAlignment'))
const DennysSales = React.lazy(() => import('pages/accounting/DennysSales'))
const InvoiceToSageCsv = React.lazy(
  () => import('pages/accounting/InvoiceToSageCsv')
)

const TimePunchReports = React.lazy(
  () => import('pages/timepunch/TimePunchReports')
)
const TimePunchAudit = React.lazy(
  () => import('pages/timepunch/TimePunchAudit')
)
const TimePunchADPExport = React.lazy(
  () => import('pages/timepunch/TimePunchADPExport')
)
const TimePunchExternalImports = React.lazy(
  () => import('pages/timepunch/TimePunchExternalImports')
)

const PosToWksAdpLinkCorrection = React.lazy(
  () => import('pages/PosToWksAdpLinkCorrection')
)
const ZoomSync = React.lazy(() => import('pages/ZoomSync'))
const GoogleDirectorySync = React.lazy(() => import('pages/GoogleDirectory'))
const AdpPdfProcessor = React.lazy(() => import('pages/AdpPdfProcessor'))
const BorrowRequest = React.lazy(() => import('pages/Borrow'))
const POSJobCodeRequest = React.lazy(() => import('pages/POSJobCode'))
const LogoutAndRefresh = React.lazy(() => import('pages/LogoutAndRefresh'))
const EsignList = React.lazy(() => import('pages/EsignList'))
const KKDKorberMapping = React.lazy(
  () => import('pages/accounting/KKDKorberMapping')
)

function LayoutInternal() {
  const clx: any = useClasses(styles)

  const user = useUser()
  const { pathname } = useLocation()
  useSessionTimeout(user)

  const navigate = useNavigate()

  const removeTrailingSlash = useCallback(
    (pathname: string) => {
      if (pathname !== '/' && pathname.endsWith('/')) {
        navigate(pathname.slice(0, -1), { replace: true })
      }
    },
    [navigate]
  )

  useEffect(() => {
    removeTrailingSlash(pathname)
  }, [pathname, removeTrailingSlash])

  useEffect(() => {
    if (
      !user.roles.canViewApp &&
      pathname !== NO_ACCESS &&
      !pathname.startsWith(ESIGN)
    ) {
      navigate(NO_ACCESS)
    }
  }, [user, pathname, navigate]) // Need location here so this will run on every url change
  // That will catch scenarios where the user hits the back button from no-access to
  // attempt to get to an authenticated page
  // See https://stackoverflow.com/a/61153138/2859142

  // Using both Material UI theme provider and styled components theme provider for flexibility
  // https://stackoverflow.com/questions/57108648/how-do-i-access-material-uis-theme-in-styled-component
  return (
    <Sentry.ErrorBoundary
      fallback={FallbackComponent(ErrorOccurred)}
      showDialog
    >
      <FullHeightBox>
        <MenuBar showSubNav={user.roles.canViewApp} key="app-bar-mobile" />
        <React.Suspense fallback={<div></div>}>
          <Main>
            <Routes>
              {/* NON-CENTERED PAGES */}
              <Route
                path={REQUEST_LIST}
                element={
                  <>
                    <Box m={2} />
                    <EmploymentChangeList />
                  </>
                }
              />
              <Route
                path={PathWithWildCard(REQUEST_LIST_PENDING_MY_APPROVAL_LEGACY)}
                element={
                  <Navigate
                    to={PathWithWildCard(REQUEST_LIST_PENDING_MY_APPROVAL)}
                    replace
                  />
                }
              />
              <Route
                path={PathWithWildCard(REQUEST_LIST_PENDING_MY_APPROVAL)}
                element={
                  <>
                    <Box m={2} />
                    <EmploymentChangeList />
                  </>
                }
              />

              <Route
                path={PathWithWildCard(PRACTIONER_ACTION_BOARD)}
                element={
                  <>
                    <Box m={2} />
                    <PractitionerActionBoard />
                  </>
                }
              />

              <Route
                path={PathWithWildCard(HR_ACTION_BOARD)}
                element={
                  <>
                    <Box m={2} />
                    <HrActionBoard />
                  </>
                }
              />
              <Route
                path={PathWithWildCard(TRAINING_ACTION_BOARD)}
                element={
                  <>
                    <Box m={2} />
                    <TrainingActionBoard />
                  </>
                }
              />
              <Route
                path={PathWithWildCard(NO_ACCESS)}
                element={<NoEnvironmentAccess />}
              />

              <Route
                path={PathWithWildCard(TERMINATION_PATH)}
                element={<Termination />}
              />
              <Route
                path={PathWithWildCard(CHANGE_OF_STATUS_PATH)}
                element={<EmploymentChangeHome />}
              />
              <Route
                path={PathWithWildCard(TIMEPUNCH_REPORTS)}
                element={<TimePunchReports />}
              />
              <Route
                path={PathWithWildCard(TIMEPUNCH_AUDIT)}
                element={<TimePunchAudit />}
              />
              <Route
                path={PathWithWildCard(TIMEPUNCH_EXTERNALIMPORTS)}
                element={<TimePunchExternalImports />}
              />
              <Route
                path={PathWithWildCard(TIMEPUNCH_ADPEXPORT)}
                element={<TimePunchADPExport />}
              />
              <Route
                path={PathWithWildCard(POS_ADP_LINK_CORRECTION_DEPRECATED)}
                element={<Navigate to={POS_ADP_LINK_CORRECTION} replace />}
              />
              <Route
                path={PathWithWildCard(POS_ADP_LINK_CORRECTION)}
                element={<PosToWksAdpLinkCorrection />}
              />
              <Route
                path={PathWithWildCard(TITLE_JOB_CODE_CHANGE_PATH)}
                element={<JobFunctionTitle />}
              />
              <Route
                path={PathWithWildCard(WAGE_CHANGE_PATH)}
                element={<RateChange />}
              />
              <Route
                path={PathWithWildCard(EPL_LOAN_PATH)}
                element={<EplLoan />}
              />
              <Route
                path={PathWithWildCard(TRANSFER_PATH)}
                element={<Transfer />}
              />
              <Route
                path={PathWithWildCard(LEAVE_OF_ABSENCE_PATH)}
                element={<LeaveOfAbsence />}
              />
              <Route
                path={PathWithWildCard(REHIRE_PATH)}
                element={<RehireReinstatement />}
              />
              <Route
                path={PathWithWildCard(RETURN_FROM_LEAVE_PATH)}
                element={<ReturnFromLeave />}
              />
              <Route
                path={PathWithWildCard(HOURLY_SALARY)}
                element={<HourlySalary />}
              />
              <Route
                path={PathWithWildCard(REQUEST_DETAIL)}
                element={<RequestDetail />}
              />
              <Route path={PathWithWildCard(HOME_PATH)} element={<Home />} />
              <Route path={PathWithWildCard(HELP_PATH)} element={<Help />} />
              <Route
                path={PathWithWildCard(FEEDBACK_PATH)}
                element={<Feedback />}
              />
              <Route path={PathWithWildCard(FAQ_PATH)} element={<FAQ />} />
              <Route
                path={PathWithWildCard(RELEASE_NOTES_PATH)}
                element={<ReleaseNotes />}
              />

              <Route
                path={PathWithWildCard(IMPERSONATE_PATH)}
                element={<Impersonate />}
              />
              <Route
                path={PathWithWildCard(STOREALIGNMENT_PATH)}
                element={<StoreAlignment />}
              />
              <Route
                path={PathWithWildCard(ACCOUNTING_DENNYSSALES_PATH)}
                element={<DennysSales />}
              />
              <Route
                path={PathWithWildCard(ACCOUNTING_INVOICE_TO_SAGE_CSV_PATH)}
                element={<InvoiceToSageCsv />}
              />
              <Route
                path={PathWithWildCard(UPLOAD_FILE_DISPLAY)}
                element={<UploadFileDisplayPage />}
              />
              <Route
                path={PathWithWildCard(UPLOAD_ADP_ROSTER_CSV)}
                element={<UploadAdpRoster />}
              />
              <Route
                path={PathWithWildCard(UPLOAD_TALENT_REEF_ROSTER_CSV)}
                element={<UploadTalentReefCsv />}
              />
              <Route
                path={PathWithWildCard(ZOOM_SYNC_PATH)}
                element={<ZoomSync />}
              />
              <Route
                path={PathWithWildCard(GOOGLE_DIRECTORY_SYNC_PATH)}
                element={<GoogleDirectorySync />}
              />
              <Route
                path={PathWithWildCard(ADP_PDF_PROCESSOR_PATH)}
                element={<AdpPdfProcessor />}
              />
              <Route
                path={PathWithWildCard(BORROW_REQUEST_PATH)}
                element={<BorrowRequest />}
              />
              <Route
                path={PathWithWildCard(POS_JOB_CODE_REQUEST_PATH)}
                element={<POSJobCodeRequest />}
              />
              <Route
                path={PathWithWildCard(ESIGN)}
                element={<LogoutAndRefresh />}
              />
              <Route
                path={PathWithWildCard(ESIGN_LIST)}
                element={<EsignList />}
              />
              <Route
                path={PathWithWildCard(ACCOUNTING_KKD_KORBER_SAGE_MAPPINGS)}
                element={<KKDKorberMapping />}
              />
              <Route
                path={PathWithWildCard(ACCOUNTING_KKD_KORBER_INVOICES)}
                element={<KKDKorberInvoices />}
              />
              <Route
                path={PathWithWildCard(SYNC_EMPLOYEE_FROM_ADP_PATH)}
                element={<SyncEmployeeFromADP />}
              />
            </Routes>
          </Main>
        </React.Suspense>
        <Footer>
          <Box>
            Check out our new training site
            <Link
              className={clx.link}
              href="https://wks.plus/login"
              color="inherit"
            >
              <Box
                component="img"
                sx={{
                  width: 50,
                }}
                alt="WKS Plus"
                src={WKSPlusLogo}
              />
            </Link>
            <Link
              className={clx.link}
              href="https://wksconnect.com/"
              color="inherit"
            >
              WKS Connect 1.0
            </Link>
            <Link
              color="inherit"
              component={RouterLink}
              to={FEEDBACK_PATH}
              className={clx.link}
            >
              {' '}
              Feedback
            </Link>
            <Link
              color="inherit"
              component={RouterLink}
              to={HELP_PATH}
              className={clx.link}
            >
              {' '}
              Help
            </Link>
          </Box>
          <CopyrightLine />
        </Footer>
      </FullHeightBox>
    </Sentry.ErrorBoundary>
  )
}

export const Layout = Sentry.withProfiler(LayoutInternal)
