import { CssBaseline } from '@mui/material'
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import GoogleAnalytics from 'GoogleAnalytics'
import { Layout } from 'Layout'
import { FallbackComponent } from 'components/FallbackComponent'
import { ErrorOccurred } from 'components/FallbackNotes'
import {
  LocalStorageErrorMessage,
  useLocalStorageAvailable,
} from 'components/LocalStorageCheck'
import { XsrfTokenProvider } from 'components/xsrf/XsrfTokenProvider'
import { AuthenticationProvider } from 'context/Authenticate'
import { AuthenticationProvider as DiscountAuthenticationProvider } from 'discount/DiscountAuthenticate'
import { GA_TRACKING_ID } from 'env'
import { useSnackbar } from 'notistack'
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import { QueryParamProvider } from 'use-query-params'
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6'
import { GetAxiosErrorMessage, isXsrfError } from 'utils'
import { DiscountLayout } from './discount/DiscountLayout'
import { discountTheme } from './discount/DiscountTheme'
import { theme } from './theme'

function AppInternal() {
  const { enqueueSnackbar } = useSnackbar()

  const isLocalStorageAvailable = useLocalStorageAvailable()
  if (!isLocalStorageAvailable) return <LocalStorageErrorMessage />

  const errorToast = (err: unknown) => {
    enqueueSnackbar(GetAxiosErrorMessage(err), { variant: 'error' })
  }

  const sendRepeatedXsrfFailuresToSentry = (
    error: unknown,
    request: unknown
  ) => {
    if (isXsrfError(error)) {
      Sentry.captureMessage(
        'Received XSRF error even after retries (if query had them). Attempt to reproduce by using browser in the issue and making sure the server XSRF cookie is present and the XSRF header is being populated from the client XSRF cookie.',
        {
          extra: {
            request,
          },
          level: 'error',
        }
      )
    }
  }

  /**
   * Show error message on any non successful API req.
   * Note that defaultOptions are overridden if you implement onError on a specific query or mutation.
   * queryCache and mutationCache onError are not overridden and run every time.
   */
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        onError: errorToast,
      },
      mutations: {
        onError: errorToast,
      },
    },
    queryCache: new QueryCache({
      onError: sendRepeatedXsrfFailuresToSentry,
    }),
    mutationCache: new MutationCache({
      onError: sendRepeatedXsrfFailuresToSentry,
    }),
  })

  return (
    <Sentry.ErrorBoundary
      fallback={FallbackComponent(ErrorOccurred)}
      showDialog
    >
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <QueryClientProvider client={queryClient}>
            <XsrfTokenProvider>
              {process.env.REACT_APP_BUILD_TARGET === 'discount' ? (
                // Discount page has GoogleAnalytics via useEmployeeTracking
                <StyledEngineProvider injectFirst>
                  <ThemeProvider theme={discountTheme}>
                    <DiscountAuthenticationProvider>
                      <DiscountLayout />
                    </DiscountAuthenticationProvider>
                  </ThemeProvider>
                </StyledEngineProvider>
              ) : (
                <GoogleAnalytics id={GA_TRACKING_ID} trackPathnameOnly>
                  <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                      <CssBaseline />
                      <AuthenticationProvider>
                        <Layout />
                      </AuthenticationProvider>
                    </ThemeProvider>
                  </StyledEngineProvider>
                </GoogleAnalytics>
              )}
              {process.env.NODE_ENV !== 'production' && <ReactQueryDevtools />}
            </XsrfTokenProvider>
          </QueryClientProvider>
        </QueryParamProvider>
      </BrowserRouter>
    </Sentry.ErrorBoundary>
  )
}

export const App = Sentry.withProfiler(AppInternal)
