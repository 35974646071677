import { Grid, Link, Toolbar, Typography } from '@mui/material'
import MenuItemBar, { MenuItemObject } from 'components/MenuItemBar'
import { UserDropDown } from 'components/UserDropDown'
import { ResponsiveAppBar } from 'components/layout/ResponsiveAppBar'
import { ResponsiveWksConnectLogo } from 'components/layout/ResponsiveWksConnectLogo'
import { ResponsiveWksLogo } from 'components/layout/ResponsiveWksLogo'
import { TopToolbarGrid } from 'components/layout/TopToolbarGrid'
import { ENV_BASED_TEXT } from 'env'
import * as path from 'link-paths'
import { Link as RouterLink } from 'react-router-dom'
import { AuthenticationConsumer, useUser } from '../context/Authenticate'
import { theme } from '../theme'

const payrollMenu: MenuItemObject = {
  name: 'Payroll',
  path: '/',
  role: 'canViewPayroll',
  subMenuItems: [
    {
      name: 'Submit New Request',
      path: path.CHANGE_OF_STATUS_PATH,
      role: 'canViewEmploymentChange',
    },
    {
      name: 'Employment Change List',
      path: path.REQUEST_LIST,
      role: 'statusChangeListDefaultToAllRequestsPage',
    },
    {
      name: 'Employment Change List',
      path: path.REQUEST_LIST_PENDING_MY_APPROVAL,
      role: 'statusChangeListDefaultToPendingMyApprovalPage',
    },
    {
      name: 'Practitioner Action Board',
      path: path.PRACTIONER_ACTION_BOARD,
      role: 'canViewPractitionerActionBoard',
    },
    {
      name: 'Upload ADP Roster',
      path: path.UPLOAD_ADP_ROSTER_CSV,
      role: 'canExportRosterCorrectionFile',
    },
    {
      name: 'Upload Talent Reef Roster',
      path: path.UPLOAD_TALENT_REEF_ROSTER_CSV,
      role: 'canExportRosterCorrectionFile',
    },
    {
      name: 'TimePunch - Reports',
      path: path.TIMEPUNCH_REPORTS,
      role: 'canViewPayrollTimepunch',
    },
    {
      name: 'TimePunch - Audit',
      path: path.TIMEPUNCH_AUDIT,
      role: 'canViewTimepunchAudit',
    },
    {
      name: 'TimePunch - ADP Export',
      path: path.TIMEPUNCH_ADPEXPORT,
      role: 'canViewTimePunchADPExportAndUpdateMappings',
    },
    {
      name: 'TimePunch - External Imports',
      path: path.TIMEPUNCH_EXTERNALIMPORTS,
      role: 'canViewPayrollTimePunchExternalImports',
    },
    {
      name: 'TimePunch - ADP Link Correction',
      path: path.POS_ADP_LINK_CORRECTION,
      role: 'canViewTimePunchADPExportAndUpdateMappings',
    },
    {
      name: 'ADP CheckVoucher PDF Grabber',
      path: path.ADP_PDF_PROCESSOR_PATH,
      role: 'canViewPayrollAdpPdfProcessor',
    },
  ],
}

const operationsMenu: MenuItemObject = {
  name: 'Operations',
  path: '/',
  role: 'canViewOperations',
  subMenuItems: [
    {
      name: 'Store Alignment',
      path: path.STOREALIGNMENT_PATH,
      role: 'canViewStoreAlignments',
    },
    {
      name: 'Email Groups',
      path: `${process.env.REACT_APP_WKS_CONNECT3_URL}${path.V3_OPERATIONS_EMAIL_GROUPS_PATH}`,
      role: 'canViewOperations',
    },
    {
      name: 'Training Action Board',
      path: path.TRAINING_ACTION_BOARD,
      role: 'canViewTrainingActionBoard',
    },
  ],
}

const accountingMenu: MenuItemObject = {
  name: 'Accounting',
  path: '/',
  role: 'canViewAccounting',
  subMenuItems: [
    {
      name: "Process Denny's Sales",
      path: path.ACCOUNTING_DENNYSSALES_PATH,
      role: 'accountingBasicAccess',
    },
    {
      name: 'Invoice to Sage CSV',
      path: path.ACCOUNTING_INVOICE_TO_SAGE_CSV_PATH,
      role: 'accountingBasicAccess',
    },
    {
      name: 'KKD Korber Invoices',
      path: path.ACCOUNTING_KKD_KORBER_INVOICES,
      role: 'canViewAccountingArKkdKorberInvoices',
    },
    {
      name: 'KKD Korber to Sage Mapping',
      path: path.ACCOUNTING_KKD_KORBER_SAGE_MAPPINGS,
      role: 'canViewAccountingArKkdKorberInvoices',
    },
    {
      name: 'AP Invoices - Action Board',
      path: `${process.env.REACT_APP_WKS_CONNECT3_URL}${path.V3_OPERATIONS_AP_INVOICES_ACTION_BOARD_PATH}`,
      role: 'canCreateAndEditInvoices',
    },
    {
      name: 'AP Invoices - Upload',
      path: `${process.env.REACT_APP_WKS_CONNECT3_URL}${path.V3_OPERATIONS_AP_INVOICES_CREATE_PATH}`,
      role: 'canCreateAndEditInvoices',
    },
  ],
}

const faqMenu: MenuItemObject = {
  name: 'FAQ',
  path: '/FAQ',
  role: 'canViewHrAndFaqLinks',
  subMenuItems: [
    {
      name: 'FAQ',
      path: path.FAQ_PATH,
    },
    {
      name: 'Release Notes',
      path: path.RELEASE_NOTES_PATH,
    },
  ],
}

const itMenu: MenuItemObject = {
  name: 'IT',
  path: '/',
  role: 'canViewIT',
  subMenuItems: [
    {
      name: 'Zoom Sync',
      path: path.ZOOM_SYNC_PATH,
      role: 'canViewZoomSync',
    },
    {
      name: 'Google Directory',
      path: path.GOOGLE_DIRECTORY_SYNC_PATH,
      role: 'canViewGoogleDirectorySync',
    },
    {
      name: 'Impersonate User',
      path: path.IMPERSONATE_PATH,
      role: 'canImpersonate',
    },
  ],
}

const riskMgmtMenu: MenuItemObject = {
  name: 'Risk Mgmt',
  path: '/',
  role: 'canViewRiskMgmt',
  subMenuItems: [
    {
      name: 'Links',
      path: `${process.env.REACT_APP_WKS_CONNECT3_URL}${path.V3_RISKMGMT_LINKS_PATH}`,
      external: true,
    },
    {
      name: 'Virtual Guard Training Video',
      path: 'https://www.youtube.com/watch?v=hu0wTPHhRzo',
      external: true,
    },
  ],
}

const hrMenu: MenuItemObject = {
  name: 'HR',
  path: '/',
  role: 'canViewHR',
  subMenuItems: [
    {
      name: 'Complaints/Investigations',
      path: 'https://wkshr.zendesk.com/hc',
      external: true,
      role: 'canViewHrAndFaqLinks',
    },
    {
      name: 'HR Policies & Procedures',
      path: 'https://drive.google.com/drive/u/0/folders/16I6u0f_hJdiqYkqjYLaxy4dqCNuPSOn9',
      external: true,
      role: 'canViewHrAndFaqLinks',
    },
    {
      name: 'WKS Policy and Procedures',
      path: 'https://drive.google.com/drive/u/0/folders/0AJJ36hWD4QVPUk9PVA',
      external: true,
      role: 'canViewHrAndFaqLinks',
    },
    {
      name: 'Benefits',
      path: 'https://drive.google.com/drive/u/0/folders/1EHrMNcGw2hie5-duHK74npew0z0ct99a',
      external: true,
      role: 'canViewHrAndFaqLinks',
    },
    {
      name: 'HR Action Board',
      path: path.HR_ACTION_BOARD,
      role: 'canViewHrActionBoard',
    },
    {
      name: 'eSign',
      path: path.ESIGN,
      role: 'canViewHrEsign',
    },
    {
      name: 'eSign List',
      path: path.ESIGN_LIST,
      role: 'canViewHrEsignList',
    },
    {
      name: 'Employee',
      path: path.SYNC_EMPLOYEE_FROM_ADP_PATH,
      role: 'canViewMenuHREmployee',
    },
    {
      name: 'Offer Letters',
      path: `${process.env.REACT_APP_WKS_CONNECT3_URL}${path.V3_HR_OFFER_LETTERS_PATH}`,
      role: 'canCreateAndViewOfferLetters',
    },
  ],
}

export function MenuBar(props) {
  const user = useUser()

  return (
    <ResponsiveAppBar>
      <Toolbar>
        <TopToolbarGrid>
          {user.roles.canViewApp ? (
            <>
              <Link component={RouterLink} to={path.HOME_PATH}>
                <ResponsiveWksConnectLogo />
              </Link>
              <Link component={RouterLink} to={path.HOME_PATH}>
                <ResponsiveWksLogo />
              </Link>
            </>
          ) : (
            <>
              <ResponsiveWksConnectLogo />
              <ResponsiveWksLogo />
            </>
          )}
          <AuthenticationConsumer>
            {(personInfo) => <UserDropDown personInfo={personInfo} />}
          </AuthenticationConsumer>
        </TopToolbarGrid>
      </Toolbar>
      <Toolbar
        style={{
          backgroundColor: theme.palette.primary.main,
          color: theme.palette.primary.contrastText,
          minHeight: '30px',
        }}
      >
        <Grid justifyContent="space-between" container>
          <Grid item>
            <MenuItemBar menuItem={payrollMenu} />
            <MenuItemBar menuItem={accountingMenu} />
            <MenuItemBar menuItem={hrMenu} />
            <MenuItemBar menuItem={riskMgmtMenu} />
            <MenuItemBar menuItem={operationsMenu} />
            <MenuItemBar menuItem={itMenu} />
            <MenuItemBar menuItem={faqMenu} />
          </Grid>

          {ENV_BASED_TEXT && (
            <Grid item>
              <Typography>{ENV_BASED_TEXT}</Typography>
            </Grid>
          )}
        </Grid>
      </Toolbar>
    </ResponsiveAppBar>
  )
}
