import { AxiosError, FileContentResult, isXsrfProtectionEnabled } from 'api'
import { xsrfHttpStatusCode, xsrfTokenCookieName } from 'appConstants'
import dayjs from 'dayjs'
import { V3_OPERATIONS_LOCATION_EDIT_PATH } from 'link-paths'
import { NavigateFunction } from 'react-router-dom'
import { JobTitleRateUnit, RateUnit } from 'types/RateUnit'
import { StatusChangeResponseBase } from 'types/StatusChangeResponse'
import { StepInfo } from 'types/StepProps'
import { UploadedFile } from 'types/UploadedFile'

export function convertToLocalTimeZone(
  req: StatusChangeResponseBase,
  date: string
) {
  const timeZone =
    req.timezone ?? Intl.DateTimeFormat().resolvedOptions().timeZone
  const localeString = dayjs(date).toDate().toLocaleString('en-US', {
    timeZone,
  })
  return localeString
}

export function getTimestampedFileName(prefix: string) {
  const newDate = dayjs().toDate()
  const date = newDate.getDate()
  const month = newDate.getMonth() + 1
  const fileName = `${prefix}_${newDate.getFullYear()}-${
    month < 10 ? `0${month}` : `${month}`
  }-${date}_${newDate.getHours()}:${newDate.getMinutes()}.csv`
  return fileName
}

export function downloadFileContentOnBrowser(file: FileContentResult): void {
  downloadBase64File(file.fileContents, file.fileDownloadName)
}

export function downloadFileOnBrowser(data: any, fileName: string): void {
  const url = window.URL.createObjectURL(new Blob([data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
  window.URL.revokeObjectURL(url)
}

export function downloadBase64File(data: string, fileDownloadName: string) {
  // Decode the base64-encoded data into a binary string
  const binaryString = window.atob(data)

  // Convert the binary string to a Uint8Array
  const uint8Array = new Uint8Array(binaryString.length)
  for (let i = 0; i < binaryString.length; i++) {
    uint8Array[i] = binaryString.charCodeAt(i)
  }

  const blob = new Blob([uint8Array], { type: 'application/octet-stream' })

  downloadFileOnBrowser(blob, fileDownloadName)
}

export function stripUndefinedParams(
  params: Record<string, string | undefined | null>
) {
  const newParams: Record<string, string> = {}
  Object.keys(params).forEach((param) => {
    if (params[param]) newParams[param] = params[param] ?? ''
  })

  return newParams
}

export type ConditionalSteps<T> = (StepInfo | ((data: T) => StepInfo | null))[]

export function calculateSteps<T>(
  steps: ConditionalSteps<T>,
  state: T
): StepInfo[] {
  return steps.reduce((prev, curr) => {
    if (typeof curr === 'function') {
      const result = curr(state)
      if (result) {
        prev.push(result)
      }
    } else {
      prev.push(curr)
    }
    return prev
  }, [] as StepInfo[])
}

export function validateEmail(email: string) {
  // https://stackoverflow.com/a/9204568
  return !!email.toLowerCase().match(/^[^@\s]+@[^@\s]+\.[^@\s]+$/)
}

export function formatCurrency(number = 0, currency = 'USD') {
  if (isNaN(number)) return 'Invalid input: ' + number

  return number.toLocaleString('en-US', {
    style: 'currency',
    currency,
  })
}
function calculateHourlyToSalaryBiweeklyAmount(primaryRate: number): number {
  return primaryRate * 40.0 * 2
}

function calculateSalaryToHourlyBiweeklyAmount(primaryRate: number): number {
  return primaryRate / 2.0 / 40.0
}

export function convertRateToBiweeklyAmount(
  primaryRate: number,
  rateUnit: RateUnit | JobTitleRateUnit
): number {
  switch (rateUnit) {
    case RateUnit.HOURLY:
      return calculateHourlyToSalaryBiweeklyAmount(primaryRate)
    case RateUnit.SALARY:
      return calculateSalaryToHourlyBiweeklyAmount(primaryRate)
    case JobTitleRateUnit.BOTH:
      return calculateHourlyToSalaryBiweeklyAmount(primaryRate)
    default:
      return 0
  }
}

export const PathWithWildCard = (path: string) => `${path}/*`

export function NumbersAreEqual(
  value1?: number,
  value2?: number,
  precision = 2
): boolean {
  if (typeof value1 === 'number' && typeof value2 === 'number') {
    const roundedValue1 = Number(value1.toFixed(precision))
    const roundedValue2 = Number(value2.toFixed(precision))
    return roundedValue1 === roundedValue2
  }
  return false
}

export function roundToDecimalPlaces(
  value: number,
  decimals: string | number = 2
) {
  return Number(Math.round(Number(value + 'e' + decimals)) + 'e-' + decimals)
}

export function handleNavigation(
  navigate: NavigateFunction,
  url: string,
  e?: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
  state?: any
) {
  e && e.preventDefault()

  if (state) navigate(url, { state })
  else navigate(url)
}

export function isMimeTypeAllowed(
  file: UploadedFile,
  allowedTypes: string[]
): boolean {
  return allowedTypes.some((type) => file.mimeType === type)
}

export const isNullOrUndefined = (value: any): value is null | undefined =>
  value === null || value === undefined

export const readFromCookie = (name: string) => {
  const escapedName = encodeURIComponent(name)

  const match = document.cookie.match(
    new RegExp('(^|;\\s*)(' + escapedName + ')=([^;]*)')
  )
  return match ? decodeURIComponent(match[3]) : ''
}

export const readXsrfTokenFromCookie = () => readFromCookie(xsrfTokenCookieName)

export const isXsrfError = (error: any) =>
  isXsrfProtectionEnabled &&
  error.response &&
  error.response.status === xsrfHttpStatusCode

export const getLocationEditUrl = (id: string | number): string => {
  return `${V3_OPERATIONS_LOCATION_EDIT_PATH}${id}`
}

export const parseQueryParamWithCommas = (query: string | null | undefined) => {
  if (!query) return undefined
  if (query.includes(',')) return query.split(',')
  return [query]
}

export function filterListBySpaceDelimitedSearch<T>(
  search: string,
  list: T[],
  limit?: number
): T[] {
  if (!search) return limit ? list.slice(0, limit) : list
  const terms = search.toLowerCase().split(/\s+/).filter(Boolean)
  const filtered = list.filter((item) =>
    terms.every((term) =>
      (typeof item === 'object' && item !== null
        ? Object.values(item)
        : [item]
      ).some((value) =>
        String(value ?? '')
          .toLowerCase()
          .includes(term)
      )
    )
  )
  return limit ? filtered.slice(0, limit) : filtered
}

export function GetAxiosErrorMessage(err: any) {
  const errObj = err as AxiosError
  const defaultErrorMessage = errObj.message

  const errors = errObj?.response?.data?.errors
  const errorDetails =
    errors && Array.isArray(errors)
      ? errors.filter((error) => !!error.Detail).map((error) => error.Detail)
      : []

  return errorDetails?.length > 0 ? errorDetails.join(' ') : defaultErrorMessage
}
