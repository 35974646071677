import { Edit } from '@mui/icons-material'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import CheckIcon from '@mui/icons-material/Check'
import ClearIcon from '@mui/icons-material/Clear'
import { TextField } from '@mui/material'
import Box, { BoxProps } from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardHeader from '@mui/material/CardHeader'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Divider from '@mui/material/Divider/Divider'
import Grid from '@mui/material/Grid'
import Paper from '@mui/material/Paper'
import { styled, Theme } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import {
  EditDataRequest,
  getRequestDetail,
  isEditActiveApi,
  postGroupApprove,
  postRequestApprove,
  postRequestCancel,
  postRequestReject,
  Reason,
} from 'api'
import { REQUEST_LIST_FROM } from 'appConstants'
import FinalCheck from 'components/FinalCheck'
import { InfoBoxNotification } from 'components/InfoBoxNotification'
import { LeaveOfAbsenceDetail } from 'components/LeaveOfAbsenceDetail'
import { LineItem } from 'components/LineItem'
import { BorrowDetail } from 'components/request-detail/BorrowDetail'
import { CompleteManuallyInPOSSection } from 'components/request-detail/CompleteManuallyInPOSSection'
import { CompleteSection } from 'components/request-detail/CompleteSection'
import { HourlySalaryDetail } from 'components/request-detail/HourlySalaryDetail'
import { JobFunctionDetail } from 'components/request-detail/JobFunctionDetail'
import { JobTitleDetail } from 'components/request-detail/JobTitleDetail'
import { POSJobCodeDetail } from 'components/request-detail/POSJobCodeDetail'
import { RateChangeDetail } from 'components/request-detail/RateDetail'
import { RehireReinstatementDetail } from 'components/request-detail/RehireReinstatementDetail'
import { TerminationDetail } from 'components/request-detail/TerminationDetail'
import { TransferDetail } from 'components/request-detail/TransferDetail'
import { SharedRequestInfo } from 'components/SharedRequestInfo'
import { StatusChip } from 'components/StatusChip'
import { User, useUser } from 'context/Authenticate'
import dayjs from 'dayjs'
import { useClasses } from 'hooks/useClasses'
import { useTitle } from 'hooks/useTitle'
import { REQUEST_LIST, REQUEST_LIST_PENDING_MY_APPROVAL } from 'link-paths'
import { useSnackbar } from 'notistack'
import { POSJobCodeInfoMessage } from 'pages/InfoMessage'
import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { theme } from 'theme'
import { EmployeeSearch } from 'types/Employee'
import { center } from 'types/PropProperty'
import { QueryNames } from 'types/Queries'
import {
  Status,
  StatusChangeDetailsRehireReinstatement,
  StatusChangeDetailsTermination,
  StatusChangeResponseBase,
} from 'types/StatusChangeResponse'
import { convertToLocalTimeZone } from 'utils'

const WrapTableCell = styled(TableCell)({
  whiteSpace: 'normal',
  overflow: 'visible',
  wordBreak: 'break-word',
  maxWidth: '6rem',
  minWidth: '6rem',
})

const PendingRequests = React.lazy(
  () => import('components/request-detail/PendingRequestsSection')
)
const AdpChangesSection = React.lazy(
  () => import('components/request-detail/AdpChangesSection')
)
const UploadSection = React.lazy(
  () => import('components/request-detail/UploadSection')
)

const DoNotShowLocationRequestTypes = ['Transfer', 'Borrow', 'Rehire']

function WorkflowDetails({
  statusChangeResponse: response,
  isEditActive,
  editData,
  setEditData,
}: {
  statusChangeResponse: StatusChangeResponseBase
  isEditActive: boolean
  editData: IEditData | undefined
  setEditData: (value) => void
}) {
  let DetailContent
  let notesLabel = 'Special Instructions'
  switch (response.type) {
    case 'Rate':
      DetailContent = RateChangeDetail
      break
    case 'Transfer':
      DetailContent = TransferDetail
      break
    case 'POSJobCode':
      DetailContent = POSJobCodeDetail
      break
    case 'Borrow':
      DetailContent = BorrowDetail
      break
    case 'Termination':
      DetailContent = TerminationDetail
      break
    case 'LeaveOfAbsence':
      DetailContent = LeaveOfAbsenceDetail
      break
    case 'JobFunction':
      DetailContent = JobFunctionDetail
      break
    case 'JobTitle':
      DetailContent = JobTitleDetail
      break
    case 'HourlySalary':
      DetailContent = HourlySalaryDetail
      break
    case 'ReturnFromLeave':
      DetailContent = null
      break
    case 'Rehire':
      DetailContent = RehireReinstatementDetail
      notesLabel = 'Reason'
      break
    default:
      return null
  }
  const details = response.details

  const rehireDetails =
    response.details as StatusChangeDetailsRehireReinstatement

  let statusContent: string
  if (response.status.includes('PendingApprover')) {
    statusContent = `Pending Approval by ${details.pendingActionByEmployeeName}`
  } else {
    statusContent = response.friendlyStatus
  }
  const effectiveDateLocaleString = convertToLocalTimeZone(
    response,
    response.effectiveDate
  )

  return (
    <>
      <SharedRequestInfo
        location={
          DoNotShowLocationRequestTypes.includes(response.type)
            ? undefined
            : response.location.desc
        }
        status={{
          label: statusContent,
          type: response.status,
        }}
        reason={details.reason}
        positionId={response.positionId}
        effectiveDate={effectiveDateLocaleString}
        notes={response.notes}
        primaryJob={
          details.existingAdpJobTitleCode && details.existingJobTitleDescription
            ? `${details?.existingAdpJobTitleCode} - ${details?.existingJobTitleDescription}`
            : undefined
        }
        isDetail={true}
        type={response.type}
        requestId={response.id}
        isEditActive={isEditActive}
        employeeId={response.employeeId}
        editData={editData}
        setEditData={setEditData}
        canEdit={response.canEdit}
        canChangeApprover={response.canChangeApprover}
        notesLabel={notesLabel}
        isReinstatement={rehireDetails?.isReinstatement}
      >
        {DetailContent && <DetailContent statusChangeResponse={response} />}
      </SharedRequestInfo>
    </>
  )
}

export function DetailCallout(props: BoxProps) {
  return (
    <Box
      bgcolor="grey.50"
      border={1}
      borderColor="grey.300"
      borderRadius="4px"
      {...props}
    />
  )
}

const getNameForType = (type: string, subType: string) => {
  if (type === 'POSJobCode') return 'POS Job Code'

  if (type === 'Rehire' && subType.includes('Reinstatement')) {
    return 'Reinstatement'
  }

  return type.replace(/([a-z])([A-Z])/g, '$1 $2')
}
const MAX_REASON_LENGTH = 300

/**
 * Logic and requests for approve/reject buttons (shown only to users with correct role)
 */
function ApproveReject({
  id,
  requestStatus,
}: {
  id: StatusChangeResponseBase['id']
  requestStatus: string
}) {
  const [rejecting, setRejecting] = useState(false)
  const queryClient = useQueryClient()
  const clx: any = useClasses(styles(theme))
  const navigate = useNavigate()
  const location = useLocation()
  const user = useUser()
  const { enqueueSnackbar } = useSnackbar()
  const [reason, setReason] = useState('')
  const [reasonErr, setReasonErr] = useState<string | null>(null)

  const onSuccess = (msg: string) => () => {
    enqueueSnackbar(msg, { variant: 'success' })
    queryClient.removeQueries(QueryNames.Requests)
    queryClient.removeQueries(QueryNames.RequestDetail)
    goBackToRequestListPage(navigate, location, user)
  }

  const { mutateAsync: approve, isLoading: isApproveLoading } = useMutation(
    requestStatus === Status.PendingApprovalByHR ||
      requestStatus === Status.PendingApprovalByTraining
      ? postGroupApprove
      : postRequestApprove,
    {
      onSuccess: onSuccess(`Request (id: ${id}) has been approved`),
    }
  )
  const { mutateAsync: reject, isLoading: isRejectLoading } = useMutation(
    postRequestReject,
    {
      onSuccess: onSuccess(`Request (id: ${id}) has been rejected`),
    }
  )
  const isLoadingAny = isApproveLoading || isRejectLoading
  const handleReject = () => {
    if (!rejecting) {
      setReasonErr('')
      setRejecting(true)
    } else if (reason === '') {
      setReasonErr('Fill out a reason before rejecting')
    } else if (reason !== '') {
      reject({ id, reason })
    }
  }
  const handleClose = () => {
    setRejecting(false)
  }
  const loadingButton = (isLoading: boolean, text: string) => {
    return isLoading ? (
      <>
        <CircularProgress size="1rem" /> Loading...
      </>
    ) : (
      text
    )
  }

  return (
    <>
      <Dialog
        onClose={handleClose}
        open={rejecting}
        aria-labelledby="reject-dialog-title"
        data-testid="reject-dialog"
      >
        <DialogTitle id="reject-dialog-title" data-testid="reject-dialog-title">
          Reason for rejection
        </DialogTitle>
        <Box p={2} pt={0} display="flex" flexDirection="column">
          <TextField
            autoFocus
            multiline
            maxRows={8}
            rows={3}
            variant="outlined"
            helperText={reasonErr}
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            error={!!reasonErr}
            InputProps={{
              inputProps: {
                'aria-labelledBy': 'reject-dialog-title',
                maxlength: MAX_REASON_LENGTH,
              },
            }}
            InputLabelProps={{ shrink: true }}
            data-testid="reject-dialog-textarea"
          />
          {/* Only show character counter when we get close to limit */}
          {MAX_REASON_LENGTH - reason.length <= 20 && (
            <Box mt="-22px" mr="6px">
              <Typography align="right" color="error" variant="subtitle2">
                {MAX_REASON_LENGTH - reason.length}
              </Typography>
            </Box>
          )}
          <Box pt={2} display="flex">
            <Button onClick={handleClose} disabled={isLoadingAny}>
              {loadingButton(isLoadingAny, 'Cancel')}
            </Button>
            <Box pl={2} />
            <Button
              className={clx.reject}
              variant="contained"
              disabled={isLoadingAny}
              disableElevation
              onClick={handleReject}
              data-testid="reject-dialog-submit"
            >
              {loadingButton(isLoadingAny, 'Submit Rejection')}
            </Button>
          </Box>
        </Box>
      </Dialog>
      <Grid container spacing={2} justifyContent="center" alignItems="stretch">
        <Grid item>
          <Button
            className={clx.reject}
            onClick={handleReject}
            disabled={isLoadingAny}
            variant="contained"
            disableElevation
            startIcon={<ClearIcon />}
          >
            Reject
          </Button>
        </Grid>
        <Grid item>
          <Button
            fullWidth
            onClick={() => {
              setRejecting(false)
              approve(id)
            }}
            disabled={isLoadingAny}
            variant="contained"
            disableElevation
            className={clx.approve}
            startIcon={<CheckIcon />}
          >
            Approve
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

/**
 * Logic and requests for cancelled button
 */
function Cancel({
  id,
  disabled,
}: {
  id: StatusChangeResponseBase['id']
  disabled: boolean
}) {
  const queryClient = useQueryClient()
  const clx: any = useClasses(styles(theme))
  const navigate = useNavigate()
  const location = useLocation()
  const user = useUser()
  const { enqueueSnackbar } = useSnackbar()

  const { mutateAsync: cancel, isLoading } = useMutation(postRequestCancel, {
    onSuccess: () => {
      enqueueSnackbar(`Request (id: ${id}) has been cancelled`, {
        variant: 'success',
      })
      queryClient.removeQueries(QueryNames.Requests)
      queryClient.removeQueries(QueryNames.RequestDetail)
      goBackToRequestListPage(navigate, location, user)
    },
  })
  return (
    <Button
      className={clx.reject}
      onClick={() => cancel(id)}
      disabled={isLoading || disabled}
      variant="contained"
      disableElevation
      startIcon={<ClearIcon />}
    >
      Cancel Request
    </Button>
  )
}

const styles = (theme: Theme) => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  [`@media print`]: {
    wrapper: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  root: {
    width: 475,
    position: 'relative',
  },
  rate: {
    color: theme.palette.success.main,
  },
  reject: {
    backgroundColor: '#fed7d7',
    color: '#742a2a',
    '&:hover': {
      backgroundColor: '#FEB2B2',
    },
  },
  approve: {
    backgroundColor: '#c6f6d5',
    color: '#22543d',
    '&:hover': {
      backgroundColor: '#9ae6b4',
    },
  },
  back: {
    backgroundColor: '#d6dbdf',
    color: '#34495e',
    '&:hover': {
      backgroundColor: '#aeb6bf',
    },
  },
})

export interface IEditData {
  approver: EmployeeSearch | null
  reason: Reason | null
  effectiveDate: Date | null
  notes?: string | null
}
function StatusChangeDetails({
  statusChange,
}: {
  statusChange: StatusChangeResponseBase
}) {
  useTitle('Status Change Details')
  const [isEditActive, setIsEditActive] = useState(false)
  const queryClient = useQueryClient()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [editData, setEditData] = useState<IEditData>()

  const { mutateAsync: editRequestCall, isLoading } = useMutation(
    isEditActiveApi,
    {
      onSuccess: (result) => {
        enqueueSnackbar(`Request Edited Successfully`, {
          variant: 'success',
        })
        // setChangeApprover(undefined)
        queryClient.removeQueries(QueryNames.RequestDetail)
        navigate(`${REQUEST_LIST}/${statusChange.id}`)
      },
    }
  )

  const handleEdit = () => {
    setIsEditActive(true)
  }
  const handleCancel = () => {
    setIsEditActive(false)
    setEditData(undefined)
  }

  const handleSave = async () => {
    const data: EditDataRequest = {
      employeeId: statusChange.employeeId,
      recordId: statusChange.id,
      ...editData,
    }

    await editRequestCall(data)
    setIsEditActive(false)
  }
  return (
    <>
      <CardHeader
        title={
          <Box display="flex" flexDirection="row" justifyContent="center">
            <Box display="flex" flexDirection="column">
              <Typography variant="h5">{`${getNameForType(
                statusChange.type,
                statusChange.subType
              )} Request`}</Typography>
              <Typography
                variant="h6"
                style={{ color: theme.palette.primary.light }}
                data-testid="employee-name"
              >
                {statusChange.employeeName}
              </Typography>
            </Box>
          </Box>
        }
      />
      <CardContent>
        <Grid container spacing={4} justifyContent="center">
          <Grid item component={Box} width="100%">
            <WorkflowDetails
              statusChangeResponse={statusChange}
              isEditActive={isEditActive}
              editData={editData}
              setEditData={setEditData}
            />
            {(statusChange.canChangeApprover || statusChange.canEdit) && (
              <Box>
                {isEditActive ? (
                  <>
                    <Button
                      variant="contained"
                      onClick={handleCancel}
                      size="small"
                      disabled={isLoading}
                    >
                      cancel edit
                    </Button>
                    &nbsp;
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleSave}
                      size="small"
                      disabled={
                        isLoading ||
                        (!editData?.approver &&
                          !editData?.notes &&
                          !editData?.reason &&
                          !editData?.effectiveDate)
                      }
                    >
                      <>
                        {editData?.approver !== undefined
                          ? 'save and email approver'
                          : 'save'}
                        {isLoading && (
                          <>
                            &nbsp;
                            <CircularProgress size={20} />
                          </>
                        )}
                      </>
                    </Button>
                  </>
                ) : (
                  <>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button
                      variant="contained"
                      onClick={handleEdit}
                      startIcon={<Edit />}
                      size="small"
                    >
                      Edit Request
                    </Button>
                  </>
                )}
              </Box>
            )}
          </Grid>
          {statusChange.status === Status.Rejected && (
            <Grid
              item
              component={Box}
              width="100%"
              display="flex"
              justifyContent={center}
              style={{ paddingTop: '2px' }}
            >
              <LineItem
                label="Rejection Note"
                value={statusChange?.rejectionReason}
                dataTestId="rejection-note"
                textAlign="center"
                labelColor={theme.palette.error.dark}
              />
            </Grid>
          )}
          {statusChange.canComplete &&
            statusChange.status === Status.PendingCompletionInADP &&
            statusChange.canSaveToADP && (
              <AdpChangesSection adpChanges={statusChange.adpChanges} />
            )}
          {statusChange.canCancel && (
            <Grid item>
              <Cancel id={statusChange.id} disabled={isEditActive} />
            </Grid>
          )}
          {statusChange.canApprove && (
            <Grid item>
              <ApproveReject
                id={statusChange.id}
                requestStatus={statusChange.status}
              />
            </Grid>
          )}
          {statusChange.canComplete &&
            statusChange.status === Status.PendingCompletionInADP && (
              <CompleteSection details={statusChange} disabled={isEditActive} />
            )}
          {statusChange.canCompleteManuallyInPOS && (
            <Grid item>
              <CompleteManuallyInPOSSection
                details={statusChange}
                disabled={isEditActive}
              />
            </Grid>
          )}
          <Grid item component={Box} width="100%" pb={1}>
            <Divider variant="middle" />
          </Grid>
          {statusChange.canComplete && (
            <Grid item>
              <PendingRequests
                pendingRequestsInAdpOnSubmit={
                  statusChange.pendingRequestsInAdpOnSubmit
                }
                pendingRequestsInAdpOnComplete={
                  statusChange.pendingRequestsInAdpOnComplete
                }
              />
            </Grid>
          )}
          {statusChange.fileUploadEnabled && (
            <UploadSection requestId={statusChange.id} />
          )}
          {statusChange.type === 'POSJobCode' ? (
            <InfoBoxNotification>
              <POSJobCodeInfoMessage />
            </InfoBoxNotification>
          ) : null}
          <Grid item>
            <Grid container direction="column" spacing={2}>
              <Grid item>
                <Typography variant="h6" color="textSecondary">
                  Audit Trail
                </Typography>
              </Grid>
              <Grid item>
                <TableContainer component={Paper} elevation={0}>
                  <Table
                    aria-label="audit trail"
                    size="small"
                    data-testid="audit-trail"
                  >
                    <TableBody>
                      {statusChange.auditTrails.map((t, idx) => {
                        const oldAndNew = t.oldValue && t.newValue
                        return (
                          <TableRow key={`audit-row-${idx}`}>
                            <TableCell>
                              <StatusChip
                                label={t.action}
                                size="small"
                                variant="outlined"
                              />
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {dayjs(t.date).format('MM/DD/YYYY h:mm A')}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Typography variant="caption">
                                {t.user}
                              </Typography>
                            </TableCell>
                            {(t.description || t.oldValue || t.newValue) && (
                              <WrapTableCell>
                                <Typography variant="caption">
                                  {t.description && t.description}
                                  {t.oldValue || t.newValue ? (
                                    <>
                                      {t.description && <br />}
                                      {oldAndNew && 'Old: '}
                                      {t.oldValue && t.oldValue}
                                      {oldAndNew && (
                                        <>
                                          <br />
                                          {'New: '}
                                        </>
                                      )}
                                      {t.newValue && t.newValue}
                                    </>
                                  ) : null}
                                </Typography>
                              </WrapTableCell>
                            )}
                          </TableRow>
                        )
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </>
  )
}

// eslint-disable-next-line import/no-default-export
export default function RequestDetailContainer() {
  const { id } = useParams<{ id: string }>()
  const clx: any = useClasses(styles(theme))
  const navigate = useNavigate()
  const location = useLocation()
  const user = useUser()

  const { data, isLoading } = useQuery(
    [QueryNames.RequestDetail, id],
    () => (id ? getRequestDetail(id) : Promise.resolve(null)),
    { enabled: !!id }
  )

  return (
    <Box flexDirection="row" display="flex" className={clx.wrapper}>
      <Card className={clx.root}>
        <Box position="absolute" top="12px" left="14px">
          <Button
            onClick={() => goBackToRequestListPage(navigate, location, user)}
            startIcon={<ArrowBackIosIcon />}
            style={{ paddingRight: '20px' }}
          >
            Back
          </Button>
        </Box>
        {isLoading && (
          <Box
            height={300}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <CircularProgress />
          </Box>
        )}
        {data?.data && <StatusChangeDetails statusChange={data.data} />}
      </Card>
      {data?.data?.details &&
        (data.data.details as StatusChangeDetailsTermination).isFinalCheck && (
          <FinalCheck data={data?.data} />
        )}
    </Box>
  )
}

export function goBackToRequestListPage(navigate, location, user: User) {
  if (location.state && location.state.from === REQUEST_LIST_FROM) {
    navigate(-1)
  } else {
    navigate(
      user.roles.statusChangeListDefaultToAllRequestsPage
        ? REQUEST_LIST
        : REQUEST_LIST_PENDING_MY_APPROVAL
    )
  }
}
